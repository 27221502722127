import React, { useEffect, useState } from "react";
import "./ShopBanner.css"; // Import CSS file

const ShopBanner = () => {
  const [visible, setVisible] = useState(true);

  // Automatically hide the banner after 4 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 4000); // 4000 milliseconds = 4 seconds

    return () => clearTimeout(timer); // Clean up timer on component unmount
  }, []);

  // Function to manually close the banner
  const closeBanner = () => {
    setVisible(false);
  };

  return (
    visible && (
      <div className="shop-banner">
        <p>
          You can now shop our products on <span className="highlight">Amazon</span> and{" "}
          <span className="highlight">Flipkart</span>!
        </p>
        <div className="logos">
          <a href="https://www.amazon.com" target="_blank" rel="noopener noreferrer">
            <img src="https://nozeperfumes.com/images/amazon_logo.png" alt="Amazon" className="logo" />
          </a>
          <a href="https://www.flipkart.com" target="_blank" rel="noopener noreferrer">
            <img src="https://nozeperfumes.com/images/Flipkart_logo.png" alt="Flipkart" className="logo" />
          </a>
        </div>
        <span className="close-btn" onClick={closeBanner}>
          ×
        </span>
      </div>
    )
  );
};

export default ShopBanner;
