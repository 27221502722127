const local = "http://localhost:5000";
const production = "https://nodejsbackend-livid.vercel.app";

let base_URL = "";
let mode = "pro";
if (mode === "pro") {
  base_URL = production;
} else {
  base_URL = local;
}
export { base_URL , mode };
